import React from 'react';

const SimplePage = () => {
  const headerStyle = {
    display: 'block',
    fontSize: '1.5em',
    marginBlockStart: '0.83em',
    marginBlockEnd: '0.83em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    fontWeight: 'bold',
    unicodeBidi: 'isolate'
  };

  const textStyle = {
    display: 'block',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    unicodeBidi: 'isolate'
  };

  const linkStyle = {
    ...textStyle,
    textDecoration: 'underline',
    color: 'inherit'
  };

  return (
    <div style={{ 
      fontFamily: 'Arial, sans-serif', 
      lineHeight: 1.4,
      fontSize: '16px',
      padding: '0 10px',
      margin: '50px auto',
      maxWidth: '650px'
    }}>
      <h2 style={headerStyle}>Jake Marlo</h2>
      <p style={textStyle}>
        jakemarlo88@gmail.com
      </p>
      <p style={textStyle}>
        <a href="https://www.linkedin.com/in/jake-marlo-51a377162/" target="_blank" rel="noopener noreferrer" style={linkStyle}>
          LinkedIn
        </a>
      </p>
    </div>
  );
};

export default SimplePage;